import React from "react";
import "./index.css";
import { Button, Container, Row, Col, Form, FormGroup, Label, Input, Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      countries: [],
      modal: false,
      activeModal: false,
      form: {
        name: '',
        email: '',
        message: '',
      },
      success: '',
      errors: [],
      loading: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    fetch("https://oruga.orugadesarrollo.com/wp-json/wp/v2/pais?order=asc", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        function(res) {
          this._isMounted &&
            this.setState({
              countries: res,
            });
        }.bind(this)
      );
  }

  clickHandler = (e) => {
    e.preventDefault()
      this.setState({
        activeModal: true,
        modal: !this.state.modal,
        form: {
          name: '',
          email: '',
          message: '',
        },
        errors: [],
        success: '',
      });
  };

  sendForm = (e) => {
    e.preventDefault();
    var self = this;
    let emailBody = {
      'your-name': this.state.form.name,
      'your-email': this.state.form.email,
      'your-message': this.state.form.message,
    };
    this.setState({loading: true});
    console.log(emailBody)
    let form = new FormData();
    for (const field in emailBody) {
        form.append(field, emailBody[field]);
    }
    fetch('https://oruga.orugadesarrollo.com/wp-json/contact-form-7/v1/contact-forms/2245/feedback',{
      method: 'POST',
      body: form,
      })
      .then(res => res.json())
      .then(function(res) {
        console.log(res.invalid_fields)
        self.setState({
          errors: res.invalid_fields ? res.invalid_fields : [],
          loading: false,
        })
        if (res.status === 'mail_sent') {
          self.setState({
            success: 'Gracias por contactarnos, te responderemos a la brevedad posible.'
          })
        }
      }
    )
  }
  

  hideModal = () => {
    this.setState({ activeModal: null });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      activeModal: !this.state.activeModal,
    });
  };

  changeName = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        name: e.target.value,
      }
    });
  }

  changeEmail = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        email: e.target.value,
      }
    });
  }

  changeMessage = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        message: e.target.value
      }
    });
  }

  render() {
    return (
      <React.Fragment>
            <Modal
              id="modalPortafolio"
              isOpen={this.state.activeModal}
              className={this.props.className}
              toggle={this.toggle}
              centered
            >
              <div className="closeModal">
                <FontAwesomeIcon
                  onClick={this.hideModal}
                  className="timesModal"
                  icon="times"
                />
              </div>
              <ModalBody>
              <Form id="contact-form" className={this.state.success !== '' ? 'success-form' : ''}>
                <FormGroup>
                  <Label for="name">Nombre:</Label>
                  <Input type="text" name="name" id="name" className="form-control-footer" onChange={(e) => this.changeName(e)} />
                  <span className="error-message">{this.state.errors.map((error) => error.into === 'span.wpcf7-form-control-wrap.your-name' && error.message)}</span>
                </FormGroup>
                <FormGroup>
                  <Label for="email">Correo:</Label>
                  <Input type="email" name="email" id="email" className="form-control-footer" onChange={(e) => this.changeEmail(e)} />
                  <span className="error-message">{this.state.errors.map((error) => error.into === 'span.wpcf7-form-control-wrap.your-email' && error.message)}</span>
                </FormGroup>
                <FormGroup>
                  <Label for="message">Mensaje:</Label>
                  <Input type="textarea" name="message" id="message" className="form-control-footer" onChange={(e) => this.changeMessage(e)} />
                  <span className="error-message">{this.state.errors.map((error) => error.into === 'span.wpcf7-form-control-wrap.your-message' && error.message)}</span>
                </FormGroup>
                <div style={{textAlign: 'center'}}>
                  {this.state.loading === true ? (
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  ) : (
                  <Button className="color-white link" 
                    onClick={(e) => this.sendForm(e)}
                    style={{
                      textTransform: 'uppercase',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      width: 'min-content',
                      margin: '0 auto',
                      marginTop: '10px',
                      background: 'transparent',
                      border: '0px',
                      borderRadius: '0px',
                      borderBottom: '1px solid white',
                    }}>
                      Enviar
                    </Button>
                    )
                }
                </div>
              </Form>
              {this.state.success !== '' && (
                <div>
                  Gracias por contactarnos, te responderemos a la brevedad posible.
                </div>
              )}
              </ModalBody>
            </Modal>
        <footer id="foo">
          <Container>
            <Row className="row-text">
              <Col md="12" className="footer-email-container">
                <a
                  href={`mailto: ${this.props.footerData.email}`}
                  rel="noopener noreferrer"
                  className="email-footer color-white link"
                >
                  {this.props.footerData.email}
                </a>
              </Col>
              <Col xs="9" className="footer-description-container color-white">
                <p>{this.props.footerData.description}</p>
              </Col>
            </Row>
            <Row>
              {this.state.countries.map((el, index) => (
                <Col
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                  key={index}
                  className="country-container"
                >
                  <img src={el.acf.bandera} alt={el.acf.bandera} />
                  <h3 className="color-white">{el.title.rendered}</h3>
                  <p className="color-white">{el.acf.telefono}</p>
                </Col>
              ))}
            </Row>
            <Row>
              <Col xs="12" className="contact-link-container">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="color-white link" onClick={(e) => this.clickHandler(e)}>
                  Contáctanos
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="app-container">
                {this.props.footerData.app.map((el, index) => (
                  <a
                    href={el.hipervinculo}
                    rel="noopener noreferrer"
                    key={index}
                    target="_blank"
                  >
                    <img src={el.imagen} alt={el.imagen} />
                  </a>
                ))}
              </Col>
            </Row>
            <Row>
              <div className="social-container">
                {this.props.footerData.social.map((el, index) => (
                  <a
                    href={el.link}
                    rel="noopener noreferrer"
                    key={index}
                    target="_blank"
                  >
                    <img src={el.imagen} alt={el.imagen} />
                  </a>
                ))}
              </div>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
