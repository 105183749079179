import React from 'react';
import { Container, Row } from 'reactstrap';
import './index.css';

const HeaderAbout = (props) => {
    console.log(props.loaded.length)
    return (
    <section id="featured-about" className={(props.loaded !== false && props.loaded.length !== 0) ? 'header-about contract' : 'header-about'}>
        <Container fluid className="p-100">
            <Row className="header-title-container">
                <h1 className={props.tituloDestacado ? 'title loaded' : 'title'}>
                    {props.tituloDestacado}
                    <span className="circle-title left"></span>
                    <span className="circle-title right"></span>
                </h1>
                <div className={props.tituloDestacado ? 'title-line loaded' : 'title-line'}></div>
            </Row>
        </Container>
    </section>
    )
}

export default HeaderAbout;