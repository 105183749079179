import React from "react";
import "./index.css";
import { Container, Row, Col } from "reactstrap";
import logo from "../../assets/images/oruga-white.png";
import inview from "in-view";
var url = window.location.pathname;

class Opciones extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      items: 
      [
        {
          title: "Facebook",
          url: "https://www.facebook.com/OrugaStudio",
          ID: 6,
          classes: "facebook"
        },
        {
          title: "Twitter",
          url: "https://twitter.com/orugastudio",
          ID: 5,
          classes: "twitter"
        },
        {
          title: "Linkedin",
          url: "https://www.linkedin.com/company/oruga-studio",
          ID: 9,
          classes: "linkedin"
        },
        {
          title: "YouTube",
          url: "https://www.youtube.com/c/orugastudio",
          ID: 8,
          classes: "youtube"
        },
        {
          title: "Instagram",
          url: "https://www.instagram.com/orugastudio/",
          ID: 7,
          classes: "instagram"
        },
      ],
      categories: [],
      direction: null,
      lastScrollTop: 0,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("scroll", this.scrollHandler);
  }

  componentDidMount() {
    inview.offset(30);
    setTimeout(function() {
      document.querySelector(".options").classList.add("fadeInMenu");
    }, 5000);
    document.addEventListener("scroll", this.handleScroll);
    this._isMounted = true;
  }

  activeCategory = (element) => {
    document.querySelectorAll(".category").forEach((element) => {
      element.classList.toggle("active", false);
    });
    element.target.classList.toggle("active", true);
  }

  handleClick = () => {
    var logo = document.querySelector(".logo-options");
    var burger = document.querySelector(".burger-container");
    var socialLine = document.querySelector(".menu-social");
    var socialButton = document.querySelector(".menu-principal-button");
    var socialLink = document.querySelectorAll(".menu-principal-link");
    var portafolio = document.querySelector(".portfolio-link");
    logo.classList.toggle("forceWhite");
    burger.classList.toggle("forceWhite");
    socialLine.classList.toggle("forceWhiteLineBg");
    socialButton.classList.toggle("forceWhite");
    socialLink &&
      socialLink.forEach((element) => {
        element.classList.toggle("forceWhite");
      });
    portafolio && portafolio.classList.toggle("forceWhite");
    let menuPrincipal = document.querySelector("#menu-principal-container");
    let hasShowClass = menuPrincipal.classList.contains("show-menu-principal");
    hasShowClass
      ? menuPrincipal.classList.remove("show-menu-principal")
      : menuPrincipal.classList.add("show-menu-principal");
    let menuItems = document.querySelectorAll(
      ".menu-container .menu-principal .menu-principal-item a"
    );
    menuItems.forEach((val, key) => {
      hasShowClass
        ? val.classList.add("unable-menu-items")
        : val.classList.remove("unable-menu-items");
    });
    let body = document.body;
    hasShowClass
      ? body.removeAttribute("style")
      : body.setAttribute("style", "overflow:hidden");
  };

  handleScroll = () => {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > this.state.lastScrollTop) {
      this.setState({
        direction: "D",
      });
    } else {
      this.setState({
        direction: "U",
      });
    }
    let lastScrollTopLocal = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    this.setState({
      lastScrollTop: lastScrollTopLocal,
    });
    //Items del menú de navegación
    var logo = document.querySelector(".logo-options");
    var burger = document.querySelector(".burger-container");
    var socialLine = document.querySelector(".menu-social");
    var socialButton = document.querySelector(".menu-principal-button");
    var socialLink = document.querySelectorAll(".menu-principal-link");
    var portafolio = document.querySelector(".portfolio-link");

      let blocks = inview(
        "#featured, #primeraSeccion, #videoReel, #segundaSeccion, #foo, #light-background, #featured-about, #featured, #blog-body, #post-body"
      ).check();
      // Un bloque en el viewport
      if (blocks.current.length === 1) {
        if (["featured", "videoReel", "foo"].includes(blocks.current[0].id)) {
          logo.classList.toggle("invertToBlack", false);
          burger.classList.toggle("invertToBlack", false);
          socialLine.classList.toggle("blackLineBg", false);
          socialButton.classList.toggle("invertToBlack", false);
          socialLink &&
            socialLink.forEach((element) => {
              element.classList.toggle("invertToBlack", false);
            });
          portafolio.classList.toggle("invertToBlack", false);
        } else {
          logo.classList.toggle("invertToBlack", true);
          burger.classList.toggle("invertToBlack", true);
          socialLine.classList.toggle("blackLineBg", true);
          socialButton.classList.toggle("invertToBlack", true);
          socialLink &&
            socialLink.forEach((element) => {
              element.classList.toggle("invertToBlack", true);
            });
          portafolio.classList.toggle("invertToBlack", true);
        }
      // Más de dos bloque sen el viewport
      } else {
        // Hacia abajo
        if (this.state.direction === "D") {
          // Negro a blanco
          if (
            (blocks.current[0].id === "featured" &&
              blocks.current[1].id === "primeraSeccion") ||
            (blocks.current[0].id === "videoReel" &&
              blocks.current[1].id === "segundaSeccion") ||
              (blocks.current[0].id === "featured-about" &&
              blocks.current[1].id === "light-background") ||
              (blocks.current[0].id === "featured-about" &&
              blocks.current[1].id === "blog-body") ||
              (blocks.current[0].id === "featured" &&
              blocks.current[1].id === "post-body")
          ) {
            logo.classList.toggle("invertToBlack", false);
            burger.classList.toggle("invertToBlack", false);
            socialLine.classList.toggle("blackLineBg", true);
            socialButton.classList.toggle("invertToBlack", true);
            socialLink &&
              socialLink.forEach((element) => {
                element.classList.toggle("invertToBlack", true);
              });
            portafolio.classList.toggle("invertToBlack", true);
            // Blanco a negro
          } else if (
            (blocks.current[0].id === "primeraSeccion" &&
              blocks.current[1].id === "videoReel") ||
            (blocks.current[0].id === "segundaSeccion" &&
              blocks.current[1].id === "foo") ||
            (blocks.current[0].id === "light-background" &&
              blocks.current[1].id === "foo") ||
              (blocks.current[0].id === "blog-body" &&
              blocks.current[1].id === "foo") ||
              (blocks.current[0].id === "post-body" &&
              blocks.current[1].id === "foo")
          ) {
            logo.classList.toggle("invertToBlack", true);
            burger.classList.toggle("invertToBlack", true);
            socialLine.classList.toggle("blackLineBg", false);
            socialButton.classList.toggle("invertToBlack", false);
            socialLink &&
              socialLink.forEach((element) => {
                element.classList.toggle("invertToBlack", false);
              });
            portafolio.classList.toggle("invertToBlack", false);
          }
          // Hacia arriba
        } else {
          // Negro a blanco
          if (
            (blocks.current[0].id === "videoReel" &&
            blocks.current[1].id === "primeraSeccion") ||
            (blocks.current[0].id === "foo" &&
            blocks.current[1].id === "segundaSeccion") ||
            (blocks.current[0].id === "foo" &&
            blocks.current[1].id === "light-background") ||
            (blocks.current[0].id === "foo" &&
            blocks.current[1].id === "blog-body") ||
            (blocks.current[0].id === "foo" &&
            blocks.current[1].id === "post-body")
          ) {
            logo.classList.toggle("invertToBlack", true);
            burger.classList.toggle("invertToBlack", true);
            socialLine.classList.toggle("blackLineBg", false);
            socialButton.classList.toggle("invertToBlack", false);
            socialLink &&
              socialLink.forEach((element) => {
                element.classList.toggle("invertToBlack", false);
              });
            portafolio.classList.toggle("invertToBlack", false);
            // Blanco a negro
          } else if (
            (blocks.current[0].id === "segundaSeccion" &&
              blocks.current[1].id === "videoReel") ||
            (blocks.current[0].id === "primeraSeccion" &&
              blocks.current[1].id === "featured") ||
            (blocks.current[0].id === "light-background" &&
              blocks.current[1].id === "featured-about") ||
              (blocks.current[0].id === "blog-body" &&
              blocks.current[1].id === "featured-about") ||
              (blocks.current[0].id === "post-body" &&
              blocks.current[1].id === "featured")
          ) {
            logo.classList.toggle("invertToBlack", false);
            burger.classList.toggle("invertToBlack", false);
            socialLine.classList.toggle("blackLineBg", true);
            socialButton.classList.toggle("invertToBlack", true);
            socialLink &&
              socialLink.forEach((element) => {
                element.classList.toggle("invertToBlack", true);
              });
            portafolio.classList.toggle("invertToBlack", true);
          }
        }
      }
  };

  render() {
    return (
      <React.Fragment>
        <section id="options" className="options" style={{ opacity: url !== "/" ? 1 : 0 }}>
          <Container fluid>
            <Row id="row1">
              <Col md="12" id="logo-home" className="top-options">
                <a href="/" className="logo-link">
                  <img src={logo} alt={logo} className="logo-options" />
                </a>
                <div className="burger-container" onClick={this.handleClick}>
                  <span className="burger-item" />
                  <span className="burger-item" />
                  <span className="burger-item" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="bottom-options">
                <li className="menu-social-container menu-social-container-share">
                  <ul className="menu-social">
                    {this.state.items.map((el) => (
                      <li
                        className="menu-social-item"
                        data-social={el.classes}
                        key={el.ID}
                      >
                        <a
                          href={el.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link menu-principal-link"
                        >
                          {el.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="menu-principal-button link">Síguenos</div>
                </li>
                {(url === "/portafolio/" || url === "/portafolio") ? (
                  <li style={{pointerEvents: 'auto'}} className="categoriesPortafolioContainer">
                    <ul className="categoriesPortafolio">
                      <li
                        className="borderBottomColorBlue category link active"
                        key="1"
                        onClick={(element) => {
                          this.props.clickWeb()
                          this.activeCategory(element)
                        }}
                      >
                        Web
                      </li>
                      <li
                        className="borderBottomColorOrange category link"
                        key="1"
                        onClick={(element) => {
                          this.props.clickMarketing()
                          this.activeCategory(element)
                        }}
                      >
                        Marketing
                      </li>
                      <li
                        className="borderBottomColorPurple category link"
                        key="1"
                        onClick={(element) => {
                          this.props.clickAudiovisual()
                          this.activeCategory(element)
                        }}
                      >
                        Audiovisual
                      </li>
                      <li
                        className="borderBottomColorGreen category link"
                        key="1"
                        onClick={(element) => {
                          this.props.clickBranding()
                          this.activeCategory(element)
                        }}
                      >
                        Branding
                      </li>                      
                    </ul>
                  </li>
                ) : (
                  <a href="/portafolio" className="portfolio-link link">
                    Portafolio
                  </a>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Opciones;
