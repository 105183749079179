import React, { Component } from 'react';
import Menu from '../../components/Menu';
import Opciones from '../../components/Opciones';
import PortafolioCarousel from '../../components/PortafolioCarousel';
import './index.css';

class Portafolio extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            projects: [],
            categories: [],
            fetching:true,
            imagen:''
        }
    }

    componentWillUnmount() {
        this._isMounted=false;
    }

    componentDidMount() {
        this._isMounted=true;
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=4', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res){
                var portafolio = document.querySelector(".categoriesPortafolioContainer");
                if(res.length !== 0){
                    this._isMounted && this.setState({
                        projects: res,
                        imagen: res.length > 0 ? res.acf : null,
                        status:res.status,
                        bgClass: 'backgroundColorBlue descriptionContainer',
                        bBotomClass: 'borderBottomColorBlue',
                        borderClass: 'borderColorBlue',
                    });
                }
                setTimeout(function() {
                    this.setState({fetching: false})
                    portafolio.classList.toggle("fadeInCategories", true);
                }.bind(this), 500)
                const selector = document.querySelector('.slick-current .visualPortafolio');
                    const url = selector.getAttribute('data-url');
                    console.log('componentDidMount '+url);
                    this.setState({
                    logo: url
                })
            }.bind(this))
            .catch(err => console.log(err));

    }
    handleClickWeb = () => {
        this.setState({ fetching: true });
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=4', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this.setState({ projects: [] });
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorBlue descriptionContainer',
                    bBotomClass: 'borderBottomColorBlue',
                    borderClass: 'borderColorBlue',
                });
                setTimeout(function() {
                    this.setState({fetching: false})
                }.bind(this), 500)
            }.bind(this))
            .catch(err => console.log(err));
    }

    handleClickMarketing = () => {
        this.setState({ fetching: true });
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=5', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this.setState({ projects: [] });
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorOrange descriptionContainer',
                    bBotomClass: 'borderBottomColorOrange',
                    borderClass: 'borderColorOrange',
                });
                setTimeout(function() {
                    this.setState({fetching: false})
                }.bind(this), 500)
            }.bind(this))
            .catch(err => console.log(err));
    }
    handleclickAudiovisual = () => {
        this.setState({ fetching: true });
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=6', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this.setState({ projects: [] });
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorPurple descriptionContainer',
                    bBotomClass: 'borderBottomColorPurple',
                    borderClass: 'borderColorPurple',
                });
                setTimeout(function() {
                    this.setState({fetching: false})
                }.bind(this), 500)
            }.bind(this))
            .catch(err => console.log(err));
    }
    handleclickBranding = () => {
        this.setState({ fetching: true });
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?categories=7', {headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this.setState({ projects: [] });
                this._isMounted && this.setState({
                    projects: res,
                    bgClass: 'backgroundColorGreen descriptionContainer',
                    bBotomClass: 'borderBottomColorGreen',
                    borderClass: 'borderColorGreen',
                });
                setTimeout(function() {
                    this.setState({fetching: false})
                }.bind(this), 500)
            }.bind(this))
            .catch(err => console.log(err));
    }

    render() {
        const { fetching } = this.state;
        return (
            <React.Fragment>
                <section id="visPortafolio">
                    <Opciones 
                        clickWeb         ={this.handleClickWeb}
                        clickMarketing   ={this.handleClickMarketing}
                        clickAudiovisual ={this.handleclickAudiovisual}
                        clickBranding    ={this.handleclickBranding}
                    />
                    <Menu />
                    <div className={fetching ? "loadingPortafolio loadingVisible" : "loadingPortafolio"}>
                        <div class="containerLoader">
                            <div class="cube">
                                <div class="side side1"></div>
                                <div class="side side2"></div>
                                <div class="side side3"></div>
                                <div class="side side4"></div>
                                <div class="side side5"></div>
                                <div class="side side6"></div>
                            </div>
                        </div>
                    </div>
                      <PortafolioCarousel 
                        proyectos        ={this.state.projects}
                        bgClass          ={this.state.bgClass}
                        bBotomClass      ={this.state.bBotomClass}
                        borderClass      ={this.state.borderClass}
                       />
                </section>
            </React.Fragment>
        );
    }
}

export default Portafolio;
