import React from 'react';
import './index.css';
import {  Col } from 'reactstrap';
import  WOW from "wowjs";

class proyecto extends React.Component {

    Height = (a) => {
        var titP = document.getElementsByClassName(a);
        if (window.matchMedia("(max-width: 768px)").matches) {
            Object.keys(titP).forEach((key) => {
                titP[key].style.height = "auto";
            })
        } else {
            if (this.props) {
                var aux = 0;
                Object.keys(titP).forEach((key) => {
                    titP[key].style.height = "auto";
                    if (titP[key].offsetHeight > aux) {
                        aux = titP[key].offsetHeight;
                    }
                })
                Object.keys(titP).forEach((key) => {
                    var r = aux + "px";
                    titP[key].style.height = r;

                })
            }
            aux = 0;
        }
    }

    componentDidMount() {
		if (typeof window !== 'undefined') {
			const wow = new WOW.WOW({
			  live: false,
			})
			wow.init()
        }
        this.Height("titP");
        this.Height("titP2");
        this.Height("parP");
        this.Height("tltItem");
        window.addEventListener("resize", () => {
            this.Height("titP");
            this.Height("titP2");
            this.Height("parP");
            this.Height("tltItem");
        });
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.Height());
    }

    render() {
        return (
            <React.Fragment>
                <Col xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} key={this.props.id} className="pDiv wow fadeIn" data-wow-delay="0.3s">
                    <div className="cont-imgP">
                        <div className="imgP" style={{backgroundImage: `url(${this.props.img})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}/>
                    </div>
                    <h3 className="titP2" dangerouslySetInnerHTML={this.props.title2}>

                    </h3>
                    <div className="label"></div>
                    <h3 className="titP" style={{borderBottom: '1px solid black', paddingBottom: '15px'}}>
                        {this.props.departamento}
                    </h3>
                    <p className="parP" dangerouslySetInnerHTML={this.props.text}></p>
                </Col>
            </React.Fragment>
        );
    }
}

export default proyecto;