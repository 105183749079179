import React from 'react';
import { Col } from 'reactstrap';
import './index.css'
var a;

class blogItems extends React.Component {
	render() {
		a={__html:this.props.text}
		return (
			<React.Fragment>
				<Col className='item-blog' id={this.props.id} xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} onClick={this.props.showPost(this.props.id)}
				>
					<a href={`blog/${this.props.slug}`} className='linkBlog'>
						<p className='dateBlog'>{this.props.date}</p>
						<div className="imgBlog" style={{ background: 'url("'+ this.props.img + '")' }} />
						<h3 className="tltBlog">{this.props.title}</h3>
						<p className="pBlog" dangerouslySetInnerHTML={a}></p>
					</a>
				</Col>
			</React.Fragment>
		)
	}
}

export default blogItems;