import React from 'react';
import './index.css';
import { Col } from 'reactstrap';
import  WOW from "wowjs";

class cliente extends React.Component {
	componentDidMount() {
		if (typeof window !== 'undefined') {
			const wow = new WOW.WOW({
			  live: false,
			})
			wow.init()
		  }
	}

	render() {
		return (
			<React.Fragment>
				<Col  className="ContentImageCliente wow fadeIn" data-wow-delay="0.3s" style={this.props.styleCol} xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} key={this.props.id}>
					<img className="imgItemCliente img-fluid" src={this.props.img} alt={this.props.img} />
				</Col>
			</React.Fragment>
		);
	}
}

export default cliente;