import React from 'react';
import './index.css';
import { Col } from 'reactstrap';
import  WOW from "wowjs";

class ItemHome extends React.Component {
	componentDidMount() {
		if (typeof window !== 'undefined') {
			const wow = new WOW.WOW({
			  live: false,
			})
			wow.init()
		  }
	}
	render() {
		return (
			<React.Fragment>
				<Col className="item-home-container" data-wow-delay={this.props.delay} style={this.props.styleCol} xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} key={this.props.id}>
					<img className="imgItem" src={this.props.img} alt={this.props.title} title={this.props.title} />
					<h3 className="tltItem">{this.props.title}</h3>
					<p className="pItem" dangerouslySetInnerHTML={this.props.text}></p>
				</Col>
			</React.Fragment>
		);
	}
}

export default ItemHome;