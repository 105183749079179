import React from 'react';
import './index.css';
import { Col, Button, Form, FormGroup, Input, Row, Container} from 'reactstrap';
import subscribe from '@input-output-hk/mailchimp-subscribe';

class FooterBlog extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted =false;
        this.state = {
            tituloFooterBlog: '',
            textoFooterBlog: '',
            email: '',
            message: '',
            description: '',
            countries: [],
            app: [],
            social: []
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
    
    componentDidMount() {
        this._isMounted = true;
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/1606',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    tituloFooterBlog: res.acf.titulo_footer_blog,
                    textoFooterBlog : res.acf.texto_footer_blog
                });
            }.bind(this))
            .catch(err => console.error(err));

        this._isMounted = true;
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123',{headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }})
        .then(res => res.json())
        .then(function(res) {
            this._isMounted && this.setState({
                description: res.acf.descripcion,
                app: res.acf.app,
                social: res.acf.redes_sociales
            });
        }.bind(this))
        .catch(err => console.error(err));

    }
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      email: value
    });
  }

  async handleSubmit() {
    try {
      const result = await subscribe({
        email: this.state.email,
        uID: 'a69447162507f44a0f2cf5d76',
        audienceID: 'e580b4147b',
        listName: 'orugastudio.us18'
      })
      return result
    } catch (error) {
      return error
    }
  }

  handleSubmitCaller = (e) => {
    e.preventDefault();
    this.handleSubmit()
    .then((response) => {
      switch (response.message) {
       case "Thank you for subscribing!":
        this.setState({message: '¡Gracias por suscribirte a nuestro newsletter!'});
       break;
       default:
          if (response.message.search("already") !== -1) {
            this.setState({message: 'La dirección de correo ya está suscrita.'});
          }else{
            this.setState({message: 'Por favor, ingresa una dirección de correo válida.'});
          }
        break
      }
    })
  } 

    render() {
        return (
            <React.Fragment>
                <footer id="foo" className='foo-blog'>
                    <Container>
                        <Row className="row-text">
                            <Col md="12" className="footer-email-container">
                                <h1>{this.state.tituloFooterBlog}</h1>
                            </Col>
                            <Col xs="9" className="p-blog color-white">
                                <p>{this.state.textoFooterBlog}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Form className="newsletter-form" method="POST" onSubmit={this.handleSubmitCaller}>
                                  <FormGroup className="row form-blog">
                                    <Col xs='12' sm='12' md='7' lg='7'>
                                        <Input className='input-blog' placeholder="Correo electrónico" onChange={this.handleChange} xs='9' name="emailInput" />
                                    </Col>
                                    <Col xs="5" sm='3' md='3' lg='2'>
                                        <Button value="ENVIAR" className='button-blog'>
                                            ENVIAR
                                        </Button>
                                    </Col>
                                    <Col xs='12' sm='12' md='7' lg='7'>
                                    <div style={{padding: '.375rem .75rem', fontWeight: 'bold', color: '#fff'}}>{this.state.message}</div>
                                    </Col>
                                    <Col xs="5" sm='3' md='3' lg='2'></Col>
                                  </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col xs="12" className="app-container">
                                {this.state.app.map((el, index) => (
                                    <a href={el.hipervinculo} rel="noopener noreferrer" key={index} target="_blank">
                                        <img src={el.imagen} alt={el.imagen} />
                                    </a>
                                ))}
                            </Col>
                        </Row> */}
                        <Row>
                            <div className="social-container">
                                {this.state.social.map((el, index) => (
                                    <a href={el.link} rel="noopener noreferrer" key={index} target="_blank">
                                        <img src={el.imagen} alt={el.imagen} />
                                    </a>
                                ))}
                            </div>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        )
    }
}

export default FooterBlog;