import React from 'react';
import './index.css';
import ItemHome from '../ItemHome';
import Titulos from '../Titulos';
import { Container, Row, Col } from 'reactstrap';
import inview from 'in-view';

class Servicios extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted= false;
        this.state = {
            post: [],
            titulo: []
        }
    }
    componentWillUnmount() {
        this._isMounted= false;
    }
    componentDidMount() {
        this._isMounted= true;
        inview('.divServiciosTld').on('enter', () => {
            let titleService = document.querySelector('.divServiciosTld .title-section-container');
            titleService.classList.add('Tlabel-width');
        });

        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/titulos?orderby=date&order=asc&menu_order=0',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
         }})
            .then(res => res.json())
            .then(function(res){
                this._isMounted && this.setState({
                    titulo: res[0]
                });
            }.bind(this))
            .catch(err => {
                console.error(err);
            });


        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/servicio',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
         }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    post: res
                });
            }.bind(this))
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <React.Fragment>
                <section id="secServicios" className="py-5">
                    <Container fluid className="p-100">
                        <Row>
                            <Col
                                className="divServiciosTld"
                                xl='12'
                                lg='12'
                                md='12'
                                sm='12'
                                xs='12'
                            >
                                <Titulos
                                    tTitulo={this.state.titulo.title && this.state.titulo.status === "publish" ? this.state.titulo.title.rendered : " "}
                                    tContent={this.state.titulo.content && this.state.titulo.status === "publish" ? this.state.titulo.content.rendered : " "}
                                />
                            </Col>
                        </Row>
                        <Row className="divFatherContainerItem" >
                            {this.state.post.map((el, index) => (
                                <ItemHome
                                    xl='3'
                                    lg='3'
                                    md='6'
                                    sm='6'
                                    xs='12'
                                    key={el.id}
                                    id={el.id}
                                    img={el.acf.imagen_servicios}
                                    title={el.title.rendered}
                                    text={{ __html: el.content.rendered }}
                                    delay={`0.${index + 4}`}
                                />
                            ))}
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Servicios;