import React from "react";
import "./index.css";
var a;

class Destacado extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      videoDestacado: "",
      imagenDestacada: "",
      condicional: "",
      tituloDestacado: "",
      textoCorto: "",
      loaded: false,
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.props.videoDestacado) {
      setTimeout(() => {
        this.setState({loaded: true});
      }, 5000)
    }
    a = this.props.condicional;
    return (
      <React.Fragment>
        <section className="featured" id="featured">
          {a === "si" ? (
            <>
              <video
                className="featured-video"
                id="featured-video"
                muted
                autoPlay
                src={this.props.videoDestacado}
              />
                <span className={this.state.loaded ? "hero__scroll active" : "hero__scroll"}>
                  <i className="chevron bottom" />
                </span>
            </>
          ) : (
            <div className="featured-img-father">
              <div
                className="featured-img"
                style={{
                  backgroundImage: "url('" + this.props.imagenDestacada + "')",
                }}
              />
              <div className={this.props.tituloDestacado ? "shade shade-loaded" : "shade"} />
              <div className="shade-text">
                <div
                  className="featured-title"
                  data-text={this.props.tituloDestacado}
                >
                  {this.props.tituloDestacado}
                </div>
                <div className="featured-texto-corto">
                  {this.props.textoCorto}
                </div>
              </div>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default Destacado;
