import React, { Component } from "react";
import Menu from "../../components/Menu";
import PrimeraSeccionHome from "../../components/PrimeraSeccionHome";
import Destacado from "../../components/Destacado";
import Opciones from "../../components/Opciones";
import Video from "../../components/Video";
import Footer from "../../components/Footer";
import "../index.css";
import SegundaSeccionHome from "../../components/SegundaSeccion";

class Home extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      videoDestacado: "",
      seccionVideo: "",
      footerData: "",
      newsletterData: "",
      loaded: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    document.body.style.overflow = "hidden"

    fetch("https://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123")
      .then((res) => res.json())
      .then(
        function(res) {
          this._isMounted &&
            this.setState({
              videoDestacado: res.acf.video_destacado,
              seccionVideo: res.acf.seccion_video,
              newsletterData: res.acf.newsletter,
              footerData: {
                email: res.acf.correo,
                description: res.acf.descripcion,
                app: res.acf.app,
                social: res.acf.redes_sociales,
              },
            });
          let video = document.querySelector("#featured-video");
          video && video.play();
          setTimeout(() => {
            this.setState({loaded: true});
            document.body.style.overflow = "auto"
          }, 5000)
        }.bind(this)
      );
  }

  render() {
    return (
      <React.Fragment>
        <Opciones />
        <Destacado
          videoDestacado={this.state.videoDestacado}
          condicional="si"
        />
        
        <Menu />
        {this.state.footerData && <PrimeraSeccionHome />}
        {this.state.footerData && <Video seccionVideo={this.state.seccionVideo} />}
        {this.state.footerData && <SegundaSeccionHome newsletterData={this.state.newsletterData} />}
        {this.state.footerData && <Footer footerData={this.state.footerData} />}
      </React.Fragment>
    );
  }
}

export default Home;
