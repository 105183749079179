import React from 'react';
import { Row } from 'reactstrap';
import HeaderAbout from "../../components/HeaderAbout";
import BlogItems from "../../components/BlogItems";
import FooterBlog from "../../components/FooterBlog";
import Menu from "../../components/Menu";
import Opciones from "../../components/Opciones";

class Blogs extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            tituloDestacado: '',
            News: [],
            fetching: false
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount() {

        this._isMounted = true;
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/1606')
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    tituloDestacado: res.slug
                });
            }.bind(this))

        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/posts',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    News: res
                });
                var titleHeight = document.querySelectorAll('.tltBlog')
                this.equalHeight(titleHeight);
            }.bind(this)).catch(err => {
                console.error(err);
            });
        if(this._isMounted && this.setState){
        }
    }

    callback = (e, dataFromChild) => {
        this.setState({ fetching: true });
        e.preventDefault();
        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/posts?categories='+dataFromChild,{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
        .then(res => res.json())
        .then(function(res) {
            this._isMounted && this.setState({
                News: res,
                fetching: false
            });
            var titleHeight = document.querySelectorAll('.tltBlog')
            this.equalHeight(titleHeight);
        }.bind(this)).catch(err => {
            console.error(err);
        })
    }

    equalHeight = (elements) =>{
        let maxHeight = 0;
        elements.forEach(function(element) {
            var currentHeight = element.offsetHeight;
            if(currentHeight > maxHeight) {
                maxHeight = currentHeight;
            }
        });
        this.asigHeight(elements, maxHeight);
    }
    asigHeight = (elements,value) => {
        elements.forEach(function(element) {
            element.style.height = value+'px';
        });
    }

    formatDate = (dateFormat) => {
        const date = new Date(dateFormat)
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
        const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date ) 
        let fd = `${day}-${month}-${year }`
        return fd
    }

    render() {
        if (this.state.News.length !== 0) {
            var portafolio = document.querySelector(".portfolio-link");
            var socialLine = document.querySelector(".menu-social");
            var socialButton = document.querySelector(".menu-principal-button");
            portafolio.classList.toggle("invertToBlack", true);
            socialLine.classList.toggle("blackLineBg", true);
            socialButton.classList.toggle("invertToBlack", true);
            var socialLink = document.querySelectorAll(".menu-principal-link");
            socialLink &&
            socialLink.forEach((element) => {
              element.classList.toggle("invertToBlack", true);
            });
        }
        return (
            <React.Fragment>
                <HeaderAbout tituloDestacado={this.state.tituloDestacado} loaded={this.state.News === [] ? false : this.state.News}/>
                <Opciones
                    callback={this.callback}
                />
                <Menu/>
                {this.state.News.length !== 0 && (
                <>
                <Row className='d-flex space-blog' id="blog-body" >
                    {this.state.fetching ? (<p>Loading...</p>) : (
                        this.state.News.map(New => (
                            <BlogItems
                                lg    = '4'
                                md    = '6'
                                sm    = '12'
                                xs    = '12'
                                date  = {this.formatDate(New.date)}
                                key   = {New.id}
                                id    = {New.id}
                                img   = {New.acf.Imgnoticia}
                                title = {New.title.rendered}
                                text  = {New.excerpt.rendered}
                                showPost = {this.props.showPost}
                                slug  = {New.slug}
                            />
                        ))
                    )}
                </Row>
                <FooterBlog/>
                </>
                )}
            </React.Fragment>
        )
    }
}


export default Blogs; 