import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import Titulo from '../../components/Titulos';
import './index.css';

const Estadisticas = (props) => (
    <React.Fragment>
        <section id="estadisticas" className="py-5">
            <Container fluid className="p-100" style={{marginTop: '-3rem'}}>
                <Row>
                    <Col>
                        <Titulo 
                            tTitulo={props.tituloEstadistica} 
                            tContent={props.contenidoEstadistica}
                        />
                    </Col>
                </Row>
                <Row style={{paddingTop: 35}}>
                    {props.estadisticas.map((el, index) => (
                        <Col xl='3' lg='3' md='6' sm='6' xs='12' className="statistics-item" key={index}>
                            <h3 className="title-statistic">{el.numero}</h3>
                            <p className="content-statistic">{el.descripcion}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    </React.Fragment>
)

export default Estadisticas;