//dependencias
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./index.css";

//componentes
import Home from "./screens/Home";
import Portafolio from "./screens/Portafolio";
import Blog from "./screens/Blog";
import IBlog from "./screens/InternaBlog";
import About from "./screens/About";

class AppRoutes extends Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			videoDestacado: "",
			imagenDestacada: "",
			tituloDestacado: "",
			textoCorto: "",
			backgroundImage: "",
			blogVideo: ""
		};
	}

	handleClick = index => id => {
		var idBlog = index;
		fetch("http://oruga.orugadesarrollo.com/wp-json/wp/v2/posts/" + idBlog)
			.then(res => res.json())
			.then(
				function(res) {
					this.setState({
						imagenDestacada: res.acf.Imgnoticia,
						tituloDestacado: res.title.rendered,
						textoCorto: res.acf.texto_corto,
						blogVideo: res.acf.seccion_video,
						backgroundImage: res.acf.seccion_video_background
					});
				}.bind(this)
			);
	};

	render() {
		return (
			<div className="main-container">
				<Switch>
					<Route exact strict path="/" component={Home} />
					<Route exact path="/portafolio" component={Portafolio} />
					<Route
						exact
						path="/blog"
						component={() => <Blog showPost={this.handleClick} />}
					/>
					<Route
						exact
						path="/blog/:slug"
						component={() => (
							<IBlog
								imagenDestacada={this.state.imagenDestacada}
								tituloDestacado={this.state.títuloDestacado}
								textoCorto={this.state.textoCorto}
								backgroundImage={this.state.backgroundImage}
								blogVideo={this.state.blogVideo}
							/>
						)}
					/>
					<Route exact path="/nosotros" component={About} />
				</Switch>
			</div>
		);
	}
}
export default AppRoutes;
