import React, { Component } from "react";
import "./index.css";
import Titulos from '../Titulos';
import Proyectos from '../Proyectos';
import { Container, Row, Col, Button } from 'reactstrap';
import inview from 'in-view';

class Portafolio extends Component {

    constructor(props) {
        super(props);
        this._isMounted=false;
        this.state = {
            Iportaf: [],
            titulo: []
        }
    }

    componentWillUnmount(){
        this._isMounted=false;
    }

    componentDidMount() {
        this._isMounted=true;
        inview('.divPortafoliosTld').on('enter', () => {
            let titleService = document.querySelector('.divPortafoliosTld .title-section-container');
            titleService.classList.add('Tlabel-width');
        });

        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/titulos?orderby=date&order=asc&menu_order=2',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                this._isMounted && this.setState({
                    titulo: res[0]
                });
            }.bind(this))
            .catch(err => {
                console.error(err);
            });

        fetch('https://oruga.orugadesarrollo.com/wp-json/wp/v2/portafolio?per_page=3&order=asc',{headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }})
            .then(res => res.json())
            .then(function(res) {
                
                this._isMounted && this.setState({
                    Iportaf: res
                });
            }.bind(this)).catch(err => {
                console.error(err);
            });

    }

    render() {
        return (
            <React.Fragment>
                <section id="secPortafolio" className="py-5">
                    <Container fluid className="p-100">
                        <Row>
                            <Col className="divPortafoliosTld">
                                <Titulos
                                    tTitulo={this.state.titulo.title && this.state.titulo.status === "publish" ? this.state.titulo.title.rendered : " "}
                                    tContent={this.state.titulo.content && this.state.titulo.status === "publish" ? this.state.titulo.content.rendered : " "}
                                />
                            </Col>
                        </Row>

                        <Row className="divFatherContainerItem" style={{paddingTop: '75px'}}>
                            {this.state.Iportaf.map(Img => (
                                <Proyectos
                                    xl='4'
                                    lg='4'
                                    md='4'
                                    sm='12'
                                    xs='12'
                                    key={Img.id}
                                    id={Img.id}
                                    img={Img.acf.imagen}
                                    title={{ __html: Img.title.rendered }}
                                    title2={{ __html: Img.title.rendered }}
                                    text={{ __html: Img.content.rendered }}
                                    imgLp={Img.acf.angle}
                                    departamento={Img.acf.nombreD}
                                />
                            ))}
                        </Row>
                        <Row>
                            <a href="/portafolio" style={{margin: '0 auto'}}>
                                <Button className="buttonMorePortfolio" value="ENVIAR">
                                    VER MÁS PROYECTOS
                                </Button>
                            </a>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }

}
export default Portafolio;