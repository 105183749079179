import React, { Component } from "react";
import "./index.css";
import NewsletterForm from "../NewsletterForm";
import { Container, Row, Col } from "reactstrap";

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }

  render() {
    return (
      <React.Fragment>
        <section id="Newsletter" className="py-5">
          <Container fluid className="p-100">
            <Row>
              <Col xs="12" className="tltNewsletter">
                <h2>{this.props.newsletterData}</h2>
              </Col>
            </Row>
            <Row>
              <NewsletterForm
                lg="3"
                md="12"
                sm="12"
                xs="12"
                lgi="3"
                mdi="12"
                smi="12"
                xsi="12"
              />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default Newsletter;
