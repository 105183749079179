import React from "react";
import "./index.css";
import { Container, Row, Col, Button, Modal, ModalBody } from "reactstrap";
import BackgroundImage1 from "../../assets/images/Flechas-26.svg";
import BackgroundImage2 from "../../assets/images/Flechas-27.svg";
import Dotdotdot from "react-dotdotdot";
import Carousel from "react-3d-carousels";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
library.add(faTimes);
// eslint-disable-next-line no-unused-vars
var a, b, c, d, e, f;

class PortafolioCarousel extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      resize: window.innerWidth,
      showC: true,
      modalShow: false,
      modal: false,
      projects: [],
      activeModal: null,
      logo: "",
      indexC: 0,
      proyecto: null,
      isDesktop: false,
      isTablet: false,
      isMobile: false,
      statusVideo: false,
      width:
        window.innerWidth < 576
          ? window.innerWidth - 75
          : 
          (window.innerWidth > 1536
          ? 1536
          : window.innerWidth - 200),
      height: window.innerWidth < 576 ? 450 : '75vh',
    };
  }

  prev = () => {
    if (this.carousel !== undefined) {
      this.carousel.prev();
    }
    this.logo();
  };

  next = () => {
    if (this.carousel !== undefined) {
      this.carousel.next();
    }
    this.logo();
  };

  drag = (e) => {};

  logo = () => {
    setTimeout(() => {
      if (this.carousel !== undefined && this.props.proyectos.length > 0) {
        var r = this.props.proyectos;
        if (this.carousel === null) {
          return;
        }
        var carousel = this.carousel.getIndex();
        this.setState({ logo: r[carousel].acf.logo_carousel });
      }
    }, 5);
  };

  clickHandler = (e, index) => {
    var project = this.props.proyectos[index];
    if (project.acf.modal) {
      this.setState({
        activeModal: true,
        modal: !this.state.modal,
        proyecto: project,
        imagen: this.props.imagen,
      });
    }
  };

  hideModal = () => {
    this.setState({ activeModal: null });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      activeModal: null,
    });
  };

  getTouches = (evt) => {
    return evt.touches || evt.originalEvent.touches;
  };

  handleTouchStart = (evt) => {
    const firstTouch = this.getTouches(evt)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  };

  handleTouchMove = (evt) => {
    if (!this.xDown || !this.yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.xDown - xUp;
    var yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */

        this.next();
        this.logo();
      } else {
        /* right swipe */
        this.prev();
        this.logo();
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
  };

  componentDidMount(e) {
    let x = document.getElementById("car");
    x.addEventListener("touchstart", this.handleTouchStart, false);
    x.addEventListener("touchmove", this.handleTouchMove, false);
    window.addEventListener("resize", this.mountCarousel);
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    window.addEventListener("resize", this.resizeEvent);
    document.body.addEventListener("click", () => {
      this.logo();
    });
    this._isMounted = true;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    window.removeEventListener("resize", this.dismountCarousel);
    this.setState({ unmounted: true });
    this._isMounted = false;
    this.logo();
  }

  componentDidUpdate() {
    window.removeEventListener("resize", this.dismountCarousel);
    this.logo();
  }

  mountCarousel = () => {
    this.setState({ showC: true });
    this.logo();
  };

  dismountCarousel = () => {
    this.setState({ showC: false });
    this.logo();
  };

  resizeEvent = () => {
    this.setState({ resize: window.innerWidth }, () => {console.log(this.state.resize)});
  };

  updatePredicate = (e) => {
    if (e === undefined) {
      return;
    }

    if (this.carousel !== undefined) {
      this.setState({
        width:
        window.innerWidth < 576
          ? window.innerWidth - 75
          : 
          (window.innerWidth > 1536
          ? 1536
          : window.innerWidth - 200),
      });
      this.carousel.handleMouseUp();
      setTimeout(
        function() {
          this.dismountCarousel();
        }.bind(this),
        400
      );
      setTimeout(
        function() {
          this.mountCarousel();
        }.bind(this),
        600
      );
    }
  };

  handleClickVideo = () => {
    var player = document.getElementById("videoModal");
    var playPauseButton = document.querySelector("#play-pause");
    var refreshicon = document.querySelector(".refresh-icon");

    if (player.paused) {
      player.play();
      this.setState({
        statusVideo: true,
      });
      setTimeout(() => {
        playPauseButton.classList.add("hide-button");
      }, 800);
    } else if (player.ended) {
      refreshicon.style.opacity = "1";
      playPauseButton.classList.remove("hide-button");
    } else {
      player.pause();
      refreshicon.style.opacity = "0";
      playPauseButton.classList.remove("hide-button");
      this.setState({
        statusVideo: false,
      });
    }
  };

  handleMouseMove = () => {
    var player = document.getElementById("videoModal");
    if (player.paused || player.ended) {
      let playPauseButton = document.querySelector("#play-pause");
      playPauseButton.classList.remove("hide-button");
    } else {
      let playPauseButton = document.querySelector("#play-pause");
      playPauseButton.classList.remove("hide-button");
      setTimeout(() => {
        playPauseButton.classList.add("hide-button");
      }, 800);
    }
  };

  renderImage = (images) => {
    const imagesOruga = images.map((image, index) => {
      return (
        <Row className="h-100" onClick={this.drag()} key={index}>
          <Col lg="8" className="visualPortafolio ">
            <div
              id={index}
              data-logo={image.acf.logo_carousel}
              className={this.props.borderClass + ' visualPortafolioImage'}
              style={{ backgroundImage: 'url("' + image.acf.imagen + '")' }}
              onClick={(e) => {
                this.clickHandler(e, index);
              }}
            />
          </Col>
          <Col lg="4" className="descriptionPortafolio ">
              <Row className={"descriptionContainer " + this.props.bgClass}>
                <Col className="titlesDescription" md="9" style={{padding: '0px'}}>
                  <h3 style={{ fontFamily: 'saira', fontWeight: 'bold'}}>{image.title.rendered}</h3>
                </Col>
                <Col md="3" className="yearDescription titlesDescription" style={{padding: '0px'}}>
                  <h3 style={{ fontFamily: 'saira', fontWeight: 'bold'}}>{image.acf.ano_de_ejecucion}</h3>
                </Col>
                <Col xs="12" className="descriptionColumn">
                  <Dotdotdot clamp={3}>
                    <p onClick={this.change}>{image.acf.descripcion_corta}</p>
                  </Dotdotdot>
                </Col>
                <Col xs="12" style={{padding: 0}}>
                  {image.acf.modal && (
                    <div>
                      <Button
                        className="verBoton link"
                        id={index}
                        color="danger"
                        onClick={(e) => {
                          this.clickHandler(e, index);
                        }}
                      >
                        VER
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
          </Col>
        </Row>
      );
    });
    const componentData = (
      <Carousel
        id="caru"
        ref={(carousel) => (this.carousel = carousel)}
        width={this.state.width}
        height={this.state.height}
        direction={"horizontal"}
        effect={"3d"}
        index={this.state.indexC}
        showC={this.state.showC}
      >
        {imagesOruga}
      </Carousel>
    );
    return componentData;
  };

  render() {
    a = {
      __html: this.state.proyecto ? this.state.proyecto.content.rendered : null,
    };
    b = {
      __html: this.state.proyecto ? this.state.proyecto.title.rendered : null,
    };
    c = { __html: this.state.proyecto ? this.state.proyecto.acf.imagen : null };
    d = {
      __html: this.state.proyecto
        ? this.state.proyecto.acf.ano_de_ejecucion
        : null,
    };
    e = { __html: this.state.proyecto ? this.state.proyecto.acf.video : null };
    f = {
      __html: this.state.proyecto
        ? this.state.proyecto.acf.video_archivo
        : null,
    };

    return (
      <React.Fragment>
        <section id="portafolioCarousel">
          <Container fluid className="containerCarousel">
            <Row>
              <Col xs="12">
                <div className="divClientLogo">
                  <Button
                    className="arrow-left"
                    onClick={this.prev}
                    style={{ backgroundImage: `url(${BackgroundImage1})` }}
                  />
                  <Button
                    className="arrow-right"
                    onClick={this.next}
                    style={{ backgroundImage: `url(${BackgroundImage2})` }}
                  />
                  <div
                    style={{
                      backgroundImage: `url(${
                        this.state.proyectos && this.carosuel
                          ? this.load()
                          : this.state.logo
                      })`,
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div
              id="car"
              style={{
                width: 100 + "%",
                height: "calc(100% - 250px)",
                margin: " auto",
                color: "white",
              }}
            >
              {this.state.showC &&
                this.props.proyectos.length > 0 &&
                this.renderImage(this.props.proyectos)}
            </div>
            <Modal
              id="modalPortafolio"
              isOpen={this.state.activeModal}
              className={this.props.className}
              toggle={this.toggle}
              centered
            >
              <div className="closeModal">
                <FontAwesomeIcon
                  onClick={this.hideModal}
                  className="timesModal"
                  icon="times"
                />
              </div>
              {f.__html === "" || f.__html === false ? (
                <div
                  style={{ backgroundImage: 'url("' + c.__html + '")' }}
                  className="modalImg"
                />
              ) : (
                <div onMouseMove={this.handleMouseMove.bind(this)}>
                  <ResponsiveEmbed className="embedModal" aspect="a16by9">
                    <video
                      className="modalVideo"
                      id="videoModal"
                      src={f.__html}
                    />
                  </ResponsiveEmbed>
                  <div
                    onClick={this.handleClickVideo}
                    className={`play-pause ${
                      this.state.statusVideo ? "play" : "pause"
                    }`}
                    id="play-pause"
                  >
                    <div className="play-button" />
                    <div className="pause-button" />
                    <div class="refresh-icon" />
                  </div>
                </div>
              )}

              <h1 dangerouslySetInnerHTML={b !== undefined ? b : ""} />
              <p className="dateModal">{d.__html}</p>
              <ModalBody dangerouslySetInnerHTML={a !== undefined ? a : ""} />
            </Modal>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default PortafolioCarousel;
