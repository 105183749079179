import React from "react";
import Opciones from "../../components/Opciones";
import Menu from "../../components/Menu";
import HeaderAbout from "../../components/HeaderAbout";
import Procesos from "../../components/Procesos";
import Estadisticas from "../../components/Estadisticas";
import Premios from "../../components/Premios";
import Certificaciones from "../../components/Certificaciones";
import Footer from "../../components/Footer";
import inview from "in-view";
import "./index.css";

class About extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      footerData: "",
      tituloDestacado: "",
      tituloProceso: "",
      contenidoProceso: "",
      procesos: [],
      tituloEstadistica: "",
      contenidoEstadistica: "",
      estadisticas: [],
      tituloPremios: "",
      contenidoPremios: "",
      logosPremios: [],
      premios: [],
      tituloCertificaciones: "",
      contenidoCertificaciones: "",
      certificaciones: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;

    fetch("https://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/123")
      .then((res) => res.json())
      .then(
        function(res) {
          this._isMounted &&
            this.setState({
              footerData: {
                email: res.acf.correo,
                description: res.acf.descripcion,
                app: res.acf.app,
                social: res.acf.redes_sociales,
              },
            });
          let video = document.querySelector("#featured-video");
          video && video.play();
        }.bind(this)
      );

    fetch("https://oruga.orugadesarrollo.com/wp-json/wp/v2/pages/1962")
      .then((res) => res.json())
      .then(
        function(res) {
          this._isMounted &&
            this.setState({
              tituloDestacado: res.acf.titulo_destacado,
              tituloProceso: res.acf.titulo_procesos,
              contenidoProceso: res.acf.contenido_procesos,
              procesos: res.acf.procesos,
              tituloEstadistica: res.acf.titulo_estadisticas,
              contenidoEstadistica: res.acf.contenido_estadisticas,
              estadisticas: res.acf.estadisticas,
              tituloPremios: res.acf.titulo_premios,
              contenidoPremios: res.acf.contenido_premios,
              logosPremios: res.acf.logos_premios,
              premios: res.acf.premios,
              tituloCertificaciones: res.acf.titulo_certificaciones,
              contenidoCertificaciones: res.acf.contenido_certificaciones,
              certificaciones: res.acf.certificaciones,
            });
          inview("#procesos .Tlabel").on("enter", () => {
            let titleService = document.querySelector(
              "#procesos .Tlabel .title-section-container"
            );
            titleService.classList.add("Tlabel-width");
          });
          inview("#estadisticas .Tlabel").on("enter", () => {
            let titleService = document.querySelector(
              "#estadisticas .Tlabel .title-section-container"
            );
            titleService.classList.add("Tlabel-width");
          });
          inview("#premios .Tlabel").on("enter", () => {
            let titleService = document.querySelector(
              "#premios .Tlabel .title-section-container"
            );
            titleService.classList.add("Tlabel-width");
          });
          inview("#certificaciones .Tlabel").on("enter", () => {
            let titleService = document.querySelector(
              "#certificaciones .Tlabel .title-section-container"
            );
            titleService.classList.add("Tlabel-width");
          });
        }.bind(this)
      );
  }

  render() {
    if (this.state.footerData) {
        var portafolio = document.querySelector(".portfolio-link");
        var socialLine = document.querySelector(".menu-social");
        var socialButton = document.querySelector(".menu-principal-button");
        portafolio.classList.toggle("invertToBlack", true);
        socialLine.classList.toggle("blackLineBg", true);
        socialButton.classList.toggle("invertToBlack", true);
        var socialLink = document.querySelectorAll(".menu-principal-link");
        console.log(socialLink)
        socialLink &&
        socialLink.forEach((element) => {
          element.classList.toggle("invertToBlack", true);
        });
    }
    return (
      <React.Fragment>
        <Opciones />
        <Menu />
        <HeaderAbout tituloDestacado={this.state.tituloDestacado} loaded={this.state.footerData ? this.state.footerData : false}/>
        {this.state.footerData && (
        <>
          <div id="light-background">
            <Procesos
              tituloProceso={this.state.tituloProceso}
              contenidoProceso={this.state.contenidoProceso}
              procesos={this.state.procesos}
            />
            <Estadisticas
              tituloEstadistica={this.state.tituloEstadistica}
              contenidoEstadistica={this.state.contenidoEstadistica}
              estadisticas={this.state.estadisticas}
            />
            <Premios
              tituloPremios={this.state.tituloPremios}
              contenidoPremios={this.state.contenidoPremios}
              logosPremios={this.state.logosPremios}
              premios={this.state.premios}
            />
            <Certificaciones
              tituloCertificaciones={this.state.tituloCertificaciones}
              contenidoCertificaciones={this.state.contenidoCertificaciones}
              certificaciones={this.state.certificaciones}
            />
          </div>
          <Footer footerData={this.state.footerData} />
        </>
        )}
      </React.Fragment>
    );
  }
}

export default About;
