import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import Titulo from '../Titulos';
import './index.css';

const Certificaciones = (props) => (
    <React.Fragment>
        <section id="certificaciones" className="py-5">
            <Container fluid className="p-100">
                <Row>
                    <Col>
                        <Titulo 
                            tTitulo={props.tituloCertificaciones} 
                            tContent={props.contenidoCertificaciones}
                        />
                    </Col>
                </Row>
                <Row>
                    {props.certificaciones.map((el, index) => (
                        <Col xl='4' lg='4' md='4' sm='4' xs='12' className="certifications-item" key={index}>
                            <img style={{width: '100%', maxWidth: 200}} src={el.icono.url} alt=""/>
                            <div style={{textAlign: 'center'}}dangerouslySetInnerHTML={{ __html: el.descripcion }} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    </React.Fragment>
)

export default Certificaciones;