import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Titulos from '../../components/Titulos';
import ItemHome from '../ItemHome';
import './index.css';

const Procesos = (props) => (
    <React.Fragment>
        <section id="procesos" className="py-5">
            <Container fluid className="p-100">
                <Row>
                    <Col>
                        <Titulos 
                            tTitulo={props.tituloProceso} 
                            tContent={props.contenidoProceso}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 35 }}>
                    {props.procesos.map((el, index) => (
                        <ItemHome 
                            xl='4'
                            lg='4'
                            md='6'
                            sm='6'
                            xs='12'
                            key={index}
                            id={index}
                            img={el.imagen_proceso}
                            title={el.titulo_proceso}
                            text={{ __html: el.contenido_proceso }}
                        />
                    ))}
                </Row>
            </Container>
        </section>
    </React.Fragment>
)

export default Procesos;