import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import Titulo from '../Titulos';
import './index.css';

const Premios = (props) => (
    <React.Fragment>
        <section id="premios" className="py-5">
            <Container fluid className="p-100">
                <Row>
                    <Col>
                        <Titulo 
                            tTitulo={props.tituloPremios} 
                            tContent={props.contenidoPremios}
                        />
                    </Col>
                </Row>
                <Row style={{justifyContent: 'center'}}>
                    <div className="container-award">
                        {props.logosPremios.map((el, index) => el.nombre_del_premio === "Premios ANDA" && <img className="image-award" src={el.imagen_del_logo.url} alt="" />)}
                        <p className="text-award">Premios ANDA</p>
                    </div>
                    {props.premios.map((el, index) => el.tipo === "Premios ANDA" && (
                        <Col xl='10' md='12' className="awards-item" style={{justifyContent: 'flex-start'}}key={index}>
                            <p className="award-awards"><span style={{fontWeight: "bold", fontFamily: 'saira'}}>{el.premio}</span> {el.descripcion !== ' ' &&  ' - ' + el.descripcion +  ' ('+ el.ano + ')'}</p>
                        </Col>
                    ))}
                </Row>
                <Row style={{justifyContent: 'center'}}>
                    <div className="container-award">
                        {props.logosPremios.map((el, index) => el.nombre_del_premio === "Promax BDA" && <img className="image-award" src={el.imagen_del_logo.url} alt="" />)}
                        <p className="text-award">Premios PROMAX BDA</p>
                    </div>
                    {props.premios.map((el, index) => el.tipo === "Promax BDA" && (
                        <Col xl='10' md='12' className="awards-item" style={{justifyContent: 'flex-start'}}key={index}>
                            <p className="award-awards"><span style={{fontWeight: "bold", fontFamily: 'saira'}}>{el.premio}</span> {el.descripcion !== ' ' &&  ' - ' + el.descripcion +  ' ('+ el.ano + ')'}</p>
                        </Col>
                    ))}
                </Row>
                <Row style={{justifyContent: 'center'}}>
                    <div className="container-award">
                        {props.logosPremios.map((el, index) => el.nombre_del_premio === "P&M" && <img className="image-award" src={el.imagen_del_logo.url} alt="" />)}
                        <p className="text-award">Premios P&M</p>
                    </div>
                    {props.premios.map((el, index) => el.tipo === "P&M" && (
                        <Col xl='10' md='12' className="awards-item" style={{justifyContent: 'flex-start'}}key={index}>
                            <p className="award-awards"><span style={{fontWeight: "bold", fontFamily: 'saira'}}>{el.premio}</span> {el.descripcion !== ' ' && ' - ' + el.descripcion +  ' ('+ el.ano + ')'}</p>
                        </Col>
                    ))}
                </Row>
                <Row style={{justifyContent: 'center'}}>
                    <div className="container-award">
                        {props.logosPremios.map((el, index) => el.nombre_del_premio === "Revista Producto" && <img className="image-award" src={el.imagen_del_logo.url} alt="" style={{ margin: 42}} />)}
                        <p className="text-award" style={{margin: '0px'}}>Revista Producto</p>
                    </div>
                    {props.premios.map((el, index) => el.tipo === "Revista Producto" && (
                        <Col xl='10' md='12' className="awards-item" style={{justifyContent: 'flex-start'}}key={index}>
                            <p className="award-awards"><span style={{fontWeight: "bold", fontFamily: 'saira'}}>{el.premio}</span> {el.descripcion !== ' ' &&  ' - ' + el.descripcion +  ' ('+ el.ano + ')'}</p>
                        </Col>
                    ))}
                </Row>
                <div style={{marginBottom: 50}}/>
            </Container>
        </section>
    </React.Fragment>
)

export default Premios;