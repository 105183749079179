import React from "react";
import "./index.css";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";
// import YouTube from 'react-youtube';

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusVideo: false,
    };
  }

  handleClick = () => {
    var player = document.getElementById("video-section");
    var playPauseButton = document.querySelector("#play-pause");

    if (player.paused || player.ended) {
      player.play();
      this.setState({
        statusVideo: true,
      });
      setTimeout(() => {
        playPauseButton.classList.add("hide-button");
      }, 800);
      document.querySelector(".bg-video-section").style.display = "none";
    } else {
      player.pause();
      playPauseButton.classList.remove("hide-button");
      this.setState({
        statusVideo: false,
      });
    }
  };

  handleMouseMove = () => {
    var player = document.getElementById("video-section");
    if (player.paused || player.ended) {
      let playPauseButton = document.querySelector("#play-pause");
      playPauseButton.classList.remove("hide-button");
    } else {
      let playPauseButton = document.querySelector("#play-pause");
      playPauseButton.classList.remove("hide-button");
      setTimeout(() => {
        playPauseButton.classList.add("hide-button");
      }, 800);
    }
  };

  render() {
    return (
      <React.Fragment>
        <section
          onMouseMove={this.handleMouseMove.bind(this)}
          className="video"
          id="videoReel"
        >
          <div id="video-reel" />
          <ResponsiveEmbed aspect="a16by9">
            <video
              className="video-section"
              id="video-section"
              src={this.props.seccionVideo}
            />
          </ResponsiveEmbed>
          <div className="bg-video-section" />
          <div
            onClick={this.handleClick}
            className={`play-pause ${
              this.state.statusVideo ? "play" : "pause"
            }`}
            id="play-pause"
          >
            <div className="play-button" />
            <div className="pause-button" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Video;
