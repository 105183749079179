import React from 'react';
import './index.css';
import { Col, Button, Form, FormGroup,  Input} from 'reactstrap';
import subscribe from '@input-output-hk/mailchimp-subscribe';


class NewsletterForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      message: '',
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      email: value
    });
  }

  async handleSubmit() {
    try {
      const result = await subscribe({
        email: this.state.email,
        uID: 'a69447162507f44a0f2cf5d76',
        audienceID: 'e580b4147b',
        listName: 'orugastudio.us18'
      })
      return result
    } catch (error) {
      return error
    }
  }

  handleSubmitCaller = (e) => {
    e.preventDefault();
    this.handleSubmit()
    .then((response) => {
      switch (response.message) {
       case "Thank you for subscribing!":
        this.setState({message: '¡Gracias por suscribirte a nuestro newsletter!'});
       break;
       default:
          if (response.message.search("already") !== -1) {
            this.setState({message: 'La dirección de correo ya está suscrita.'});
          }else{
            this.setState({message: 'Por favor, ingresa una dirección de correo válida.'});
          }
        break
      }
    })
  } 

  render() {
    return (
      <Col xs="12">
        <Form className="newsletter-form" method="POST" onSubmit={this.handleSubmitCaller}>
          <FormGroup className="row">
            <Col xs='12' sm='12' md='7' lg='7'>
                <Input placeholder="Correo electrónico" onChange={this.handleChange} value={this.state.email} xs='9' name="emailInput" />
            </Col>
            <Col xs="5" sm='3' md='3' lg='2'>
              <Button value="ENVIAR">
                ENVIAR
              </Button>
            </Col>
            <Col xs='12' sm='12' md='7' lg='7'>
              <div style={{padding: '.375rem .75rem', fontWeight: 'bold'}}>{this.state.message}</div>
            </Col>
            <Col xs="5" sm='3' md='3' lg='2'></Col>
          </FormGroup>
        </Form>
      </Col>
    )
  }
}


export default NewsletterForm;