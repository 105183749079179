import React from 'react';
import './index.css';
import Servicios from '../Servicios';
import Clientes from '../Clientes';
import Portafolio from '../Portafolio';

class PrimeraSeccionHome extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section id="primeraSeccion" className="primeraSeccion">
                    <Servicios />
                    <Clientes />
                    <Portafolio />
                </section>
            </React.Fragment>
        );
    }
}

export default PrimeraSeccionHome;