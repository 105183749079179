import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import './index.css'
import {
	EmailIcon,
	FacebookIcon,
	TwitterIcon,
	WhatsappIcon,
  } from "react-share";
var a, title;
var url = 'https://orugastudio.com'+window.location.pathname;

class contenidoPost extends React.Component {
	render(){
		a = { __html: this.props.content }
		title = this.props.title
		return(
			<React.Fragment>
				<Container fluid id='post-body' className='seccion-contenido p-100'>
					<Row>
						<Col lg='12' dangerouslySetInnerHTML= {a !== undefined ? a : ""} />
						{this.props.content && (
						<Col lg='12' className="share-container">
							<div className="share-title">Compartir en:</div>
							<div className="share-buttons">
								<a href={'mailto:?body='+ title + ' - ' + url +'&subject=' + title}>
									<EmailIcon size={32} round={true} />
								</a>
								<a target="_blank" rel="noopener noreferrer" href={'https://www.facebook.com/sharer.php?u='+url}>
									<FacebookIcon size={32} round={true} />
								</a>
								<a target="_blank" rel="noopener noreferrer" href={'https://twitter.com/intent/tweet?url=' + url + '&text=' + title}>
									<TwitterIcon size={32} round={true} />
								</a>
								<a target="_blank" rel="noopener noreferrer" href={'whatsapp://send?text='+ title + ' - ' + url}>
									<WhatsappIcon size={32} round={true} />
								</a>
							</div>
						</Col>
						)}
					</Row>
				</Container>
			</React.Fragment>
		)
	}
}
export default contenidoPost;